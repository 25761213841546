import { SET_LOCATION } from "./actions";

export const initialState = {
  location: {},
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOCATION:
      return { ...state, ...{ location: payload } };
    default:
      return state;
  }
};
